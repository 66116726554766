import types from './types';

export default {
  async getMessages ({ commit }) {
    try {
      const msgs = await this.$ChatAPI.getTelenesChatMessages().then(res => res.data);
      // console.log('getMessages ========>', msgs);
      commit(types.GET_MESSAGES_SUCCESS, msgs);
    } catch (error) {
      commit(types.GET_MESSAGES_FAIL, error);
    }
  },
  async getChatModelMessages ({ commit }, modelId) {
    try {
      const msgs = await this.$ChatAPI.getTelenesChatModelMessages(modelId).then(res => res.data);
      // console.log('getChatModelMessages ========>', msgs);
      commit(types.GET_MESSAGES_SUCCESS, msgs);
    } catch (error) {
      commit(types.GET_MESSAGES_FAIL, error);
    }
  },
  async getLastMessages ({ commit }) {
    try {
      const category = this.$auth.$storage.getCookie('category');
      const chats = await this.$ChatAPI.getTelenesLastMessages(category).then(res => res.data);
      // console.log('getLastMessages ========>', chats);
      commit(types.GET_CHATS_SUCCESS, chats);
    } catch (error) {
      commit(types.GET_CHATS_FAIL, error);
    }
  },
  async getChats ({ commit }) {
    try {
      const chats = await this.$ChatAPI.getChatsList();
      // console.log('getChats ========>', chats);
      commit(types.GET_CHATS_SUCCESS, chats);
    } catch (error) {
      commit(types.GET_CHATS_FAIL, error);
    }
  },
  async setChat ({ commit }, chatInfo) {
    try {
      const chats = await this.$ChatAPI.sendChatInfo(chatInfo);
      // console.log('---- SETCHAT ACTION ----', chatInfo);
      // console.log('---- SETCHAT result ----', result);
      commit(types.SET_CHAT_SUCCESS, chats);
    } catch (error) {
      commit(types.SET_CHAT_FAIL, error);
    }
  },
  setChatModel (modelId) {
    // state.chatModel = modelId;
  }
};
