export default {
  error: state => state.error,
  loading: state => state.loading,
  configData: state => state.configData,
  geo: state => state.geoData,
  geoDataCity: state => state.geoData.city?.names.en,
  geoDataCountry: state => state.geoData.country.names,
  geoDataLocation: state => state.geoData.city_models?.city_name,
  geoDataIp: state => state.geoData.traits.ip_address,
  geoDataCityModels: state => state.geoData.city_models,
  geoDataLat: state => state.geoData.location.latitude,
  geoDataLng: state => state.geoData.location.longitude
};
