export default {
  GET_MODELS_SUCCESS: 'GET_MODELS_SUCCESS',
  GET_MODELS_FAIL: 'GET_MODELS_FAIL',

  REACT_ON_MODEL_SUCCESS: 'REACT_ON_MODEL_SUCCESS',
  REACT_ON_MODEL_FAIL: 'REACT_ON_MODEL_FAIL',

  SWIPER_UPDATE_MODELID_SUCCESS: 'SWIPER_UPDATE_MODELID_SUCCESS',
  SWIPER_UPDATE_MODELID_FAIL: 'SWIPER_UPDATE_MODELID_FAIL'
};
