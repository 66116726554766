export const AGE_SLIDER_DEFAULTS = {
  MIN_AGE: 18,
  MAX_AGE: 65,
  RANGE: [21, 60]
};

export const COLORS = {
  ORANGE: '#ff8d28',
  ORANGE_GRADIENT: 'linear-gradient(90deg, #ff6565 -2.93%, #ffdc23 105.33%)',
  PURPLE: '#565dff',
  PINK: '#FF37A3',
  GREY: '#828282',
  GREY_LIGHT: '#AAADB3'
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female'
};

export const REGISTRATION_STEPS = {
  GENDER: 'gender',
  CITY: 'city',
  EMAIL: 'email'
};

export const REGISTRATION_STEPS_ORDER = [
  REGISTRATION_STEPS.GENDER,
  REGISTRATION_STEPS.CITY,
  REGISTRATION_STEPS.EMAIL
];

export const WHATSAPP_STEPS = {
  PHONE_NUMBER: 'number',
  VERIFICATION_CODE: 'code',
  CONFIRMATION: 'confirmation'
};

export const WHATSAPP_STEPS_ORDER = [
  WHATSAPP_STEPS.PHONE_NUMBER,
  WHATSAPP_STEPS.VERIFICATION_CODE,
  WHATSAPP_STEPS.CONFIRMATION
];

export const SWIPER_SLIDE = {
  DEFAULT_ID: 2
};
export const TOAST_SETTINGS = {
  position: 'top-center',
  maxToasts: 1,
  timeout: 7000
};
