export { default as BillingForm } from '../../components/BillingForm.vue'
export { default as CreditCardForm } from '../../components/CreditCardForm.vue'
export { default as GooglePay } from '../../components/GooglePay.vue'
export { default as ApplePayButton } from '../../components/ApplePayButton/ApplePayButton.vue'
export { default as BackButton } from '../../components/BackButton/BackButton.vue'
export { default as Button } from '../../components/Button/Button.vue'
export { default as ChatBlock } from '../../components/ChatBlock/ChatBlock.vue'
export { default as ChatModalWindow } from '../../components/ChatModalWindow/ChatModalWindow.vue'
export { default as ChatTelenesLogic } from '../../components/ChatTelenesLogic/ChatTelenesLogic.vue'
export { default as ChatTwilioLogic } from '../../components/ChatTwilioLogic/ChatTwilioLogic.vue'
export { default as ChatUI } from '../../components/ChatUI/ChatUI.vue'
export { default as Checkbox } from '../../components/Checkbox/Checkbox.vue'
export { default as CongratuationsPopup } from '../../components/CongratuationsPopup/CongratuationsPopup.vue'
export { default as ContentModal } from '../../components/ContentModal/ContentModal.vue'
export { default as ContentModalCall } from '../../components/ContentModalCall/ContentModalCall.vue'
export { default as ContentModalConfirm } from '../../components/ContentModalConfirm/ContentModalConfirm.vue'
export { default as ContentModalEmailVerify } from '../../components/ContentModalEmailVerify/ContentModalEmailVerify.vue'
export { default as ContentModalMessage } from '../../components/ContentModalMessage/ContentModalMessage.vue'
export { default as ContentModalOffer } from '../../components/ContentModalOffer/ContentModalOffer.vue'
export { default as ContentModalTerms } from '../../components/ContentModalTerms/ContentModalTerms.vue'
export { default as ContentModalVerify } from '../../components/ContentModalVerify/ContentModalVerify.vue'
export { default as Footer } from '../../components/Footer/Footer.vue'
export { default as GenderToggle } from '../../components/GenderToggle/GenderToggle.vue'
export { default as GirlsSlider } from '../../components/GirlsSlider/GirlsSlider.vue'
export { default as GirlsSliderSlide } from '../../components/GirlsSlider/Slide.vue'
export { default as Header } from '../../components/Header/Header.vue'
export { default as Like } from '../../components/Like/Like.vue'
export { default as Link } from '../../components/Link/Link.vue'
export { default as Loader } from '../../components/Loader/Loader.vue'
export { default as Modal } from '../../components/Modal/Modal.vue'
export { default as ModalCancelMembership } from '../../components/ModalCancelMembership/ModalCancelMembership.vue'
export { default as ModalCrop } from '../../components/ModalCrop/ModalCrop.vue'
export { default as ModalDatePicker } from '../../components/ModalDatePicker/ModalDatePicker.vue'
export { default as ModalInput } from '../../components/ModalInput/ModalInput.vue'
export { default as ModalInputEmailVerify } from '../../components/ModalInputEmailVerify/ModalInputEmailVerify.vue'
export { default as ModalSelect } from '../../components/ModalSelect/ModalSelect.vue'
export { default as ModalWrap } from '../../components/ModalWrap/ModalWrap.vue'
export { default as ModelSelectCityModalCitySelect } from '../../components/ModelSelectCity/ModalCitySelect.vue'
export { default as ModelSlider } from '../../components/ModelSlider/ModelSlider.vue'
export { default as NewMessageEvent } from '../../components/NewMessageEvent/NewMessageEvent.vue'
export { default as NotificationBlock } from '../../components/NotificationBlock/NotificationBlock.vue'
export { default as PhoneVerifyCode } from '../../components/PhoneVerifyCode/PhoneVerifyCode.vue'
export { default as PhoneVerifyConfirm } from '../../components/PhoneVerifyConfirm/PhoneVerifyConfirm.vue'
export { default as PhoneVerifyFlow } from '../../components/PhoneVerifyFlow/PhoneVerifyFlow.vue'
export { default as PhoneVerifyNumber } from '../../components/PhoneVerifyNumber/PhoneVerifyNumber.vue'
export { default as PhotoUpload } from '../../components/PhotoUpload/PhotoUpload.vue'
export { default as ProfileInput } from '../../components/ProfileInput/ProfileInput.vue'
export { default as PurchaseOk } from '../../components/PurchaseOk/PurchaseOk.vue'
export { default as RangeSelector } from '../../components/RangeSelector/RangeSelector.vue'
export { default as Select } from '../../components/Select/Select.vue'
export { default as SelectCity } from '../../components/SelectCity/SelectCity.vue'
export { default as SendMessage } from '../../components/SendMessage/SendMessage.vue'
export { default as TelephoneInput } from '../../components/TelephoneInput/TelephoneInput.vue'
export { default as TextField } from '../../components/TextField/TextField.vue'
export { default as TheFormCity } from '../../components/TheFormCity/TheFormCity.vue'
export { default as TheFormEmail } from '../../components/TheFormEmail/TheFormEmail.vue'
export { default as TheFormUsername } from '../../components/TheFormUsername/TheFormUsername.vue'
export { default as TheFormGender } from '../../components/TheFormGender/TheFormGender.vue'
export { default as TheProfileForm } from '../../components/TheProfileForm/TheProfileForm.vue'
export { default as TheProfileFormField } from '../../components/TheProfileFormField/TheProfileFormField.vue'
export { default as Toast } from '../../components/Toast/Toast.vue'
export { default as ToastButton } from '../../components/Toast/ToastButton.vue'
export { default as ToastMessage } from '../../components/Toast/ToastMessage.vue'
export { default as TokenAbsent } from '../../components/TokenAbsent/TokenAbsent.vue'
export { default as TokenGiven } from '../../components/TokenGiven/TokenGiven.vue'
export { default as TokenOffer } from '../../components/TokenOffer/TokenOffer.vue'
export { default as TokenOfferIcon } from '../../components/TokenOfferIcon/TokenOfferIcon.vue'
export { default as TokensFlow } from '../../components/TokensFlow/TokensFlow.vue'
export { default as TokensTopUp } from '../../components/TokensTopUp/TokensTopUp.vue'
export { default as WelcomeChat } from '../../components/WelcomeChat/WelcomeChat.vue'
export { default as WhatsAppCode } from '../../components/WhatsAppCode/WhatsAppCode.vue'
export { default as WhatsAppConfirm } from '../../components/WhatsAppConfirm/WhatsAppConfirm.vue'
export { default as WhatsAppFlow } from '../../components/WhatsAppFlow/WhatsAppFlow.vue'
export { default as WhatsAppNumber } from '../../components/WhatsAppNumber/WhatsAppNumber.vue'
export { default as ChatUIInputButton } from '../../components/ChatUI/input/InputButton.vue'
export { default as ChatUIInputContainer } from '../../components/ChatUI/input/InputContainer.vue'
export { default as ChatUIInputField } from '../../components/ChatUI/input/InputField.vue'
export { default as ChatUIMessagesMessageForeign } from '../../components/ChatUI/messages/MessageForeign.vue'
export { default as ChatUIMessagesMessageOwn } from '../../components/ChatUI/messages/MessageOwn.vue'
export { default as ChatUIMessages } from '../../components/ChatUI/messages/Messages.vue'
export { default as ChatUIMessagesList } from '../../components/ChatUI/messages/MessagesList.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
