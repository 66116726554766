import types from './types';

export default {
  async getModelsList ({ commit }, { category }) {
    try {
      const models = await this.$ModelsAPI.getModelsList({ category });
      commit(types.GET_MODELS_SUCCESS, models);
    } catch (error) {
      commit(types.GET_MODELS_FAIL, error);
    }
  },
  async reactOnModel ({ commit, state }, { isLike }) {
    const id = Number(state.swiperCurrentModelId);

    try {
      await this.$ModelsAPI.like({ id, isLike });

      commit(types.REACT_ON_MODEL_SUCCESS, { id, isLike });
    } catch (error) {
      commit(types.REACT_ON_MODEL_FAIL, error);
    }
  },
  updateSwiperModelId ({ commit }, id) {
    try {
      commit(types.SWIPER_UPDATE_MODELID_SUCCESS, id);
    } catch (error) {
      commit(types.SWIPER_UPDATE_MODELID_FAIL, error);
    }
  }
};
