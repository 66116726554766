export default {
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAIL: 'GET_MESSAGES_FAIL',

  GET_CHATS_SUCCESS: 'GET_CHATS_SUCCESS',
  GET_CHATS_FAIL: 'GET_CHATS_FAIL',

  SET_CHAT_SUCCESS: 'SET_CHAT_SUCCESS',
  SET_CHAT_FAIL: 'SET_CHAT_FAIL'
};
