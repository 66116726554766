<template>
  <transition-group
    name="messages-list"
    tag="div"
    @enter="onEnter"
  >
    <!-- <div class="messages-list" ref="messagesContainer"> -->
    <div
      v-for="(message, index) in feed"
      :key="`${index}-msg`"
      class="messages-list-item"
    >
      <div v-if="showDateHeader(message.created_at, index)" class="date-header">
        {{ getDate(message.created_at) }}
      </div>
      <messages
        :from-model="message.from === 'model'"
        :date="message.created_at"
        :image-url="message.imageUrl"
        :contents="message.text"
        :sent="message.sent"
      />
    </div>
  <!-- </div> -->
  </transition-group>
</template>

<script>
import Messages from './Messages.vue';

const moment = require('moment');
let curdate = null;

export default {
  name: 'MessagesList',
  components: {
    Messages
  },
  props: {
    feed: {
      type: Array,
      default: function () {
        return [];
      },
      required: false
    },
    authorId: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data: () => ({
  }),
  computed: {
  },
  mounted () {
    // this.scrollToEnd()
    moment.locale(this.$i18n.locale);
  },
  beforeDestroy () {
    curdate = null;
  },
  methods: {
    onEnter (el, done) {
      this.$nextTick(el.scrollIntoView());
    },
    // scrollToEnd () {
    //   console.log('scroll ', this.feed.length, this.$refs.messagesContainer.scrollHeight)
    //   const el = this.$refs.messagesContainer
    //   el.scrollIntoView({ behavior: "smooth", block: "end" })
    //   // scrollTop = this.$refs.messagesContainer.scrollHeight
    // },
    showDateHeader (date, idx) {
      // console.log('dates ', this.getDate(curdate), this.getDate(date))
      if (this.getDate(curdate) !== this.getDate(date) || idx === 0) {
        curdate = date;
        return true;
      } else {
        return false;
      }
    },
    datefromnow (date) {
      return moment(date).format('LT');
    },
    getDate (date) {
      return moment().format('ll') === moment(date).format('ll') ? this.$i18n.t('chat.Today') : moment(date).format('ll');
    },
    isOwn (messageFrom) {
      return messageFrom === 'model' ? 1 : 0;
    }
  }
};
</script>

<style lang="scss">
.messages-list-item {
  padding: 1%
}
.metadata {
  float: right;
  padding: 0 0 0 3px;
  position: relative;
  bottom: 2px;
  height: 10px;
  display: block;
  right: 7px;
}
.metadata.before_footer {
  margin-top: -14px;
}
.metadata .time {
  font-size: 12px;
  font-weight: 500;
}
.metadata .tick {
  position: absolute;
  margin-left: 5px;
  top: 3px;
}
.date-header {
  padding: 3px;
  margin: 15px;
  border-radius: 20px;
  background: rgba(241, 242, 243, 0.8);
}
</style>
