import types from './types';
import { USER_TOKEN_KEY } from '@/utils/constants/base';

export default {
  async registerUser ({ commit }, userInfo) {
    try {
      const data = await this.$UserAPI.login(userInfo);
      // console.log('registerUser ========>', data)

      localStorage.setItem(USER_TOKEN_KEY, data.access_token);
      this.$auth.$storage.setCookie(USER_TOKEN_KEY, data.access_token);
    } catch (error) {
      // console.log('registerUser error ========>', error)
      commit(types.REGISTRATION_FAIL, error);
      throw error;
    }
  },
  async checkEmail ({ commit }, email) {
    try {
      const data = await this.$UserAPI.emailVerify(email);
      return data;
    } catch (error) {
      return error;
    }
  },
  async checkUsername ({ commit }, name) {
    try {
      const data = await this.$UserAPI.usernameVerify(name);
      return data;
    } catch (error) {
      return error;
    }
  },
  async getCities ({ commit }, params) {
    try {
      const city = await this.$ConfigAPI.getCities(params.input, params.lang);

      commit(types.GET_CITIES_SUCCESS, city);
    } catch (error) {
      commit(types.GET_CITIES_FAIL, error);
    }
  }
};
