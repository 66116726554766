export default {
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAIL: 'GET_PROFILE_FAIL',
  EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAIL: 'EDIT_PROFILE_FAIL',

  SET_WHATSAPP_NUMBER_SUCCESS: 'SET_WHATSAPP_NUMBER_SUCCESS',
  SET_WHATSAPP_NUMBER_FAIL: 'SET_WHATSAPP_NUMBER_FAIL',

  CHECK_WHATSAPP_CODE_SUCCESS: 'CHECK_WHATSAPP_CODE_SUCCESS',
  CHECK_WHATSAPP_CODE_FAIL: 'CHECK_WHATSAPP_CODE_FAIL',

  CHECK_EMAIL_TOKEN_SUCCESS: 'CHECK_EMAIL_TOKEN_SUCCESS',
  CHECK_EMAIL_TOKEN_FAIL: 'CHECK_EMAIL_TOKEN_FAIL',

  DECREMENT_SUCCESS: 'DECREMENT_SUCCESS',
  DECREMENT_FAIL: 'DECREMENT_FAIL',

  GETPIN_SUCCESS: 'GETPIN_SUCCESS',
  GETPIN_FAIL: 'GETPIN_FAIL',

  CHECK_PAYMENT_SUCCESS: 'CHECK_PAYMENT_SUCCESS',
  CHECK_PAYMENT_FAIL: 'CHECK_PAYMENT_FAIL',

  SET_PHONE_NUMBER_SUCCESS: 'SET_PHONE_NUMBER_SUCCESS',
  SET_PHONE_NUMBER_FAIL: 'SET_PHONE_NUMBER_FAIL',

  CHECK_PHONE_CODE_SUCCESS: 'CHECK_PHONE_CODE_SUCCESS',
  CHECK_PHONE_CODE_FAIL: 'CHECK_PHONE_CODE_FAIL',

  GET_LIKES_SUCCESS: 'GET_LIKES_SUCCESS',
  GET_LIKES_FAIL: 'GET_LIKES_FAIL'
};
