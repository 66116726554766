import types from './types';

export default {
  async getModelDetails ({ commit }, modelId) {
    try {
      const modelDetails = await this.$ModelsAPI.getModelInfo(modelId);

      commit(types.GET_MODEL_DETAILS_SUCCESS, modelDetails.data);
    } catch (error) {
      commit(types.GET_MODEL_DETAILS_FAIL, error);
    }
  }
};
