import axios from 'axios';
import $store from '~/store';

const flowKeywords = {
  "hi, nice profile, how are you?": 'icebreaker_replies1',
  "hi, can you talk now?": 'icebreaker_replies2',
  "i like what i see": 'icebreaker_replies3',
  "let's get naughty": 'icebreaker_replies4',
  meet: 'meet_replies',
  hook: 'meet_replies',
  hookup: 'hookup_replies',
  romantic: 'romantic_replies',
  online: 'cam_replies',
  cam: 'cam_replies',
  // facetime: 'facetime_replies',
  // availability_response: 'availability_response_replies',
  // private: 'private_replies',
  // intimate: 'intimate_replies',
  // playful: 'playful_replies',
  // fun: 'fun_replies',
  // seductive: 'seductive_replies',
  // tease: 'tease_replies',
  // chat: 'chat_replies',
  // video_call: 'video_call_replies',
  // adventure: 'adventure_replies',
  // exploration: 'exploration_replies',
  // date: 'date_replies',
  // connection: 'connection_replies',
  // exciting: 'exciting_replies',
  // spicy: 'spicy_replies',
  // flirt: 'flirty_replies',
  // sassy: 'sassy_replies',
  deep: 'deep_talk_replies'
};

const flowMessages = {
  'letsgetnaughty_replies': [
    "let's get naughty bb, I'm in the mood - we open cam here or facetime?",
    "what for naughty stuff r u looking for bb?",
    "what for naughty stuff you enjoy {username} one?"
  ],
  icebreaker_replies: [
    "Hi bb! Tell me something special about yourself",
    "heyy, how are you?",
    "Hii {username}, all good?",
    "heyy {username}, how's it going?",
    "hii {username}, how are you? what's ur name please?",
    "Hii {username}, how are you doing?",
    "mmm {username}, how are you?",
    "hii bb",
    "heyy {username}, all good bb?"
  ],
  icebreaker_replies1: [
    "Heyy {username}, all good thx and u? Also look’n for funn? Online funn or meetup bb?",
    "Heyy {username}, all good here thx, U? 😉 Also lookn for funn? U fancy to continue online or a casual meetup bb? 😏"
  ],
  icebreaker_replies2: [
    "Eyy {username}, sure I can talk bb, what are you lookn for? Also Funn? Wanne meetup or first online funn?",
    "Sure {username}, I'm up for a chat! What are you after bb? Fancy some online funn or lookn for a casual meetup? 😏"
  ],
  icebreaker_replies3: [
    "mmm thx {username}, would love to see you also bb Are you also looking for funn? Meetup or online funn for now?",
    "mmm thx {username}, would love to see ya too! 😘 U up for some funn? Casual meetup or to continue online for now bb?"
  ],
  icebreaker_replies4: [
    "mmm why not {username}, I’m here and also lookn for funn. U’r looking to meetup or online funn now bb?",
    "Heyy {username}, mmm why not! I’m up for casual fun too. U fancy a hot meet-up or to continue with online funn for starters, bb? 😉"
  ],
  free_chat_replies: [
    "Hi {username}, I'm here and ready to chat! What are you looking for today?",
    'Hey {username}, glad you messaged me. Are you looking for something fun or romantic?',
    "Hello {username}, it's great to hear from you! What’s on your mind today?"
  ],
  meet_replies: [
    "Just heard I’ll be solo till tomorrow, so I’ve got some time to kill. Fancy swinging by for drinks and a bit of funn?😉 Or got any other ideas? Let me know😘",
    "coo bb, getting a bit excited already😘 can I open cam here or we use facetime or other, so u can see? Wanna show you I'm the real deal!"
  ],
  'cam_replies': [
    "Hey hot stuff bb😏 I'm up for some online fun too! Gonna hop in the shower quick, but I'll be ready for ya after😘 When's good for you? xx",
    "coo bb, getting a bit excited already😘 can I open cam here or we use facetime or other, so u can first see? Wanna show you I'm the real deal!"
  ],
  // post_chat_triggers: [
  //   "Hey {username}, you've used up your free chats. Want to continue? Purchase a trial now!"
  // ],
  followup_replies: [
    'Hi bb, are you still here?',
  ],
  trigger: [
    "U still about, bb? 😏 I’m keen to connect now… x",
    "I guess u're busy bb, but hit me up when U're back online! Can't wait to get this going 😘",
  ],
  follow_up_replies: [
    // "what are you looking for bb? Funn??",
    "are you looking for online fun or meetup?",
    // "what are you looking for? Funn??)",
    "want a video or we open cam and chat there {username}?",
    // "i got time, home alone looking for fun",
    "you look for onliine fun or meetup?",
    "live on cam or meetup bb?",
    // "what u got in mind?"
  ],
  hookup_replies: [
    'A hookup sounds exciting, {username}! When are you available for that?',
    'That’s great, {username}! Let’s plan for the hookup. What time works for you?',
    'I’m ready for a hookup, {username}! When would be the best time for you?'
  ],
  romantic_replies: [
    'I’m in the mood for something romantic, {username}. How about you?',
    'Let’s talk about something sweet, {username}. What’s your idea of romance?',
    'I love a good romantic chat, {username}. What’s on your mind?'
  ],
  deep_talk_replies: [
    'Let’s get deep, {username}. What’s on your heart?',
    'I’m all for deep talks, {username}. Let’s open up.',
    'Let’s talk about something deep, {username}. What’s your biggest dream?'
  ],
  alternatives: [
    "Go on...",
    "Try again"
  ]
};

const payload = {
  models: [
    'Anthracite-Org/Magnum-v4-72b',
    'aphrodite/knifeayumu/Cydonia-v1.3-Magnum-v4-22B',
    'aphrodite/knifeayumu/Cydonia-v1.3-Magnum-v4-22B',
    'Behemoth-123B-v1.2-4.0bpw-h6-exl2',
    'koboldcpp/Behemoth-123B-v1.2',
    'koboldcpp/Cydonia-v1.3-Magnum-v4-22B',
    'koboldcpp/Cydonia-v1.3-Magnum-v4-22B',
    'koboldcpp/Gemma-2-9B-Blackout-R1',
    'koboldcpp/L3-8B-Stheno-v3.2',
    'koboldcpp/Llama-3.1-8B-Stheno-v3.4-Q8_0-imat',
    'koboldcpp/Llama-3.1-8B-Stheno-v3.4-Q8_0-imat',
    'koboldcpp/Llama-3.2-1B-Instruct-Uncensored.Q4_K_M',
    'koboldcpp/Llama-3.2-3B-Instruct',
    'koboldcpp/Llama-3.2-3B-Instruct-Q4_K_M',
    'koboldcpp/Llama-3.2-3B-Instruct-Q4_K_S',
    'koboldcpp/Lyra-Gutenberg-mistral-nemo-12B',
    'koboldcpp/Lyra-Gutenberg-mistral-nemo-12B',
    'koboldcpp/magnum-v4-22b-Q5_K_L',
    'koboldcpp/Meta-Llama-3.1-8B-Instruct',
    'koboldcpp/mini-magnum-12b-v1.1',
    'koboldcpp/mini-magnum-12b-v1.1-iMat-Q4_K_M',
    'koboldcpp/Mistral-Nemo-12B-ArliAI-RPMax-v1.1.i1-Q6_K',
    'koboldcpp/Mistral-Nemo-12B-ArliAI-RPMax-v1.1.i1-Q6_K',
    'koboldcpp/NemoMix-Unleashed-12B',
    'NousResearch/Hermes-2-Pro-Mistral-7B',
    'tabbyAPI/EVA-UNIT-01_EVA-Qwen2.5-32B-v0.2-exl2-4.1bpw'
  ],
  // models: ['koboldcpp/Mistral-Nemo-12B-ArliAI-RPMax-v1.1.i1-Q6_K'],
  params: {
    dynatemp_exponent: 1,
    dynatemp_range: 0,
    max_context_length: 4096,
    max_length: 200,
    min_p: 0,
    n: 1,
    rep_pen: 1.07,
    rep_pen_range: 360,
    rep_pen_slope: 0.7,
    sampler_order: [
      6,
      0,
      1,
      3,
      4,
      2,
      5
    ],
    smoothing_factor: 0,
    stop_sequence: [
      '##User_name##:',
      '\n##User_name## ',
      '\n##Char_name##: '
    ],
    temperature: 0.75,
    tfs: 1,
    top_a: 0,
    top_k: 100,
    top_p: 0.92,
    typical: 1,
    use_default_badwordsids: false
  },
  prompt: '[Character: ##Char_name##; species: Human; age: ##Char_age##; gender: female; physical appearance: cute, attractive; personality: cheerful, upbeat, friendly; likes: chatting; description: ##Char_name## is outgoing, adventurous, and enjoys many interesting hobbies. She has had a secret crush on you for a long time.]\n',
  workers: []
};

const poll = async (id) => {
  // const startTime = Date.now();
    while (true) {
        try {
            const response = await axios.get(`https://stablehorde.net/api/v2/generate/text/status/${id}`);
            console.log(response);
            if (response.status === 200) {
                const result = response.data;
                if (result.status === "completed") {
                    return result.generated_text;
                }
            }
        } catch (error) {
            console.error("Error waiting for response:", error);
        }
        await new Promise(resolve => setTimeout(resolve, 5000));  // Wait for 5 seconds before checking again
    }

  // try {
  //   const { data } = await axios.get(`https://stablehorde.net/api/v2/generate/text/status/${id}`);
  //   console.log(data);

  //   if (data.faulted) {
  //     console.log('Fault encountered during text generation from AI Horde.');
  //     return;
  //   }

  //   if (data.done) {
  //     console.log(`The request was successfully processed from AI Horde.`);
  //     return data.generations[0].text;
  //   }
  // } catch {
  //   console.log('The request was failed.');
  // }
  // setTimeout(poll, 5000, id);//Math.max(0, 4000 - (Date.now() - startTime)));
};

const getModels = async (apiKey) => {
  try { 
    const response = await axios.get('https://stablehorde.net/api/v2/status/models?type=text', {}, {
      headers: {
        'Content-Type': 'application/json',
        apykey: apiKey
      }
    });
    console.log(response);
    if (response.status === 200) { 
      return response.data.map(model => model.name); 
    } 
  } catch (error) { 
    console.error("Error fetching model names:", error);
  }
  return [];
};

const botReply = async (messages, username, modelName, modelAge, ai = false) => {
  const reply = {};
  const apiKey = "0000000000";
  if (ai) {
    try {
      const models = await getModels(apiKey);
      payload.models = models.filter(name => !name.includes('CohereForAI'));
      // payload.prompt = payload.prompt.replaceAll('##Char_name##', modelName);
      // payload.prompt = payload.prompt.replaceAll('##Char_age##', modelAge);
      // payload.prompt = payload.prompt.replaceAll('##User_name##', username);
      payload.prompt = messages.map(message => {
        if (message.from === 'model') {
          return `${modelName}: ${message.text}`
        } else {
          return `${username}: ${message.text}`
        }
      }).join('\n');
      // payload.prompt += `${username}: ${message}`;
      payload.stop_sequence = [
            `${username}:`,
            `\n${username} `,
            `\n${modelName}: `
      ];
      // console.log('prompt', payload.prompt); return
      await generateText(payload, apiKey).then(generatedText => {
        console.log(generatedText);
        reply.id = Date.now();
        reply.message = generatedText;
      });
    
      // const { data } = await axios.post('https://stablehorde.net/api/v2/generate/text/async', payload, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     apikey: '0000000000'
      //   }
      // });
      // if (data.id) {
      //   console.log('A new request has been added for processing to AI Horde.', data.id);
      //   return await poll(data.id);
      // }
    } catch (error) {
      console.log('The new request was not added for processing to AI Horde due to an error.', error);
    }
  } else {
    const flowType = (message) => {
      // Find the full key that matches the partial key
      const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
      const fullKey = Object.keys(flowKeywords).find(key => message.replace(emojiRegex, '').toLowerCase().trim().includes(key));
      // Get the value based on the full key
      return flowKeywords[fullKey];
    };
    const message = messages[messages.length - 1]?.text;
    let flow = '';
    switch (messages.filter(m => m.from === 'user').length) {
      case 1:
        flow = flowType(message) ?? 'free_chat_replies';
        break;
      case 2:
        flow = flowType(message) ?? 'follow_up_replies';
        break;
      case 3:
        flow = window.$nuxt.$store.state.messages.previousFlow ?? flowType(message) ?? 'follow_up_replies';
        break;
      default:
        flow = 'free_chat_replies';
    }
    window.$nuxt.$store.commit('messages/setPreviousFlow', flow);    
    reply.id = Date.now();
    const capitalize = s => (s && String(s[0]).toUpperCase() + String(s).slice(1));
    let flowIndex = 0;
    if ( !['meet_replies', 'cam_replies'].includes(flow) ) {
      flowIndex = Math.floor(Math.random() * flowMessages[flow].length);
    } else if (messages.filter(m => m.from === 'user').length === 3) {
      flowIndex = 1;
    }
    reply.message = flowMessages[flow][flowIndex].replace('{username}', capitalize(username));
    // console.log(message, flow, window.$nuxt.$store.state.messages.previousFlow);
    // let attempts = 0; 
    // const maxAttempts = messages.length;
    // while (messages.includes(reply.message) && attempts < maxAttempts) { 
    //   reply.message = flowMessages[flow][flowIndex].replace('{username}', capitalize(username));
    //   attempts++;
    // }
  }
  return reply;
};

async function generateText(payload, apiKey) {
    const url = "https://stablehorde.net/api/v2/generate/text/async";
    const headers = {
      // apykey: `${apiKey}`,
      apikey: '0000000000',
      "Content-Type": "application/json"
    };

    try {
      const { data } = await axios.post(url, payload, { headers: headers });
      if (data.id) {
        return await waitForResponse(data.id, apiKey);
      }
    } catch (error) {
      console.error("Error generating text:", error);
    }
    return null;
}

async function waitForResponse(jobId, apiKey) {
    const url = `https://stablehorde.net/api/v2/generate/text/status/${jobId}`;
    const headers = {
      // apykey: `${apiKey}`,
      apikey: '0000000000'
    };

    while (true) {
      try {
        const { data } = await axios.get(url, { headers: headers });
        console.log(data);
        if (data.faulted) {
          console.log('Fault encountered during text generation from AI Horde.');
          return;
        }

        if (data.done) {
          console.log(`The request was successfully processed from AI Horde.`);
          return data.generations[0].text;
        }
        // if (response.status === 200) {
        //     const result = response.data;
        //     if (result.status === "completed") {
        //         return result.generated_text;
        //     }
        // }
      } catch (error) {
        console.error("Error waiting for response:", error);
      }
      await new Promise(resolve => setTimeout(resolve, 5000));  // Wait for 5 seconds before checking again
    }
}

export default botReply;
