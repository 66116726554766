/* eslint-disable no-throw-literal */
import { USER_TOKEN_KEY } from '@/utils/constants/base';
// import { load } from '@fingerprintjs/botd';

export default function ({ req, route, store, redirect, $auth, query }) {
// console.log('route', route.name)
// return
// load()
//   .then((botd) => botd.detect()?.bot)
//   .then((result) => console.log(result))
//   .catch((error) => console.error(error));
  if (!route.name) {
    throw ({ statusCode: 404, message: 'Not Found' });
  }
  const hostname = (req)
    ? (req.headers.host.match(/:/g)) ? req.headers.host.slice(0, req.headers.host.indexOf(':')) : req.headers.host
    : window.location.hostname;
  const h = ['localhost', 'app-f.flirtypoint.com', '172.16.100.12', '192.168.1.92'];

  if (!(query.e1 || $auth.$storage.getCookie('_cid')) && !h.includes(hostname) && ['lp-a', 'lp-s', 'lp-ac', 'lp-sc', 'lp-sa', 'rm7', 'sf', 'lp-ab', 'lp-sab'].includes(route.name)) {
    throw ({ statusCode: 405, message: 'Method Not Allowed' });
    // redirect('https://duckduckgo.com');
    // return;
  }
  if (hostname !== 'flirtypoint.com' && !h.includes(hostname) && ['lp-a', 'lp-s', 'rm7', 'lp-sa', 'sf', 'lp-ab', 'lp-sab', 'lp-ac', 'lp-sc'].includes(route.name)) {
    redirect(`https://flirtypoint.com${route.fullPath}`);
    return;
  }

  const token = $auth.$storage.getCookie(USER_TOKEN_KEY);

  // store category into cookie
  if (query.category) {
    $auth.$storage.setCookie('category', query.category);
  }

  if (['unsubscribe-token', 'campaign', 'id-name', 'lp-a', 'lp-c', 'lp-i', 'model-id', 'expired', 'lp-d', 'lp-x', 'lp-s', 'lp-sa', 'sf', 'am4d', 'rm7', 'sk', 'regb', 'rm7', 'lp-ab', 'lp-sab', 'lp-ac', 'lp-sc', 'tg'].includes(route.name)) { return; }

  // If the user is not authenticated
  if (!token) {
    return redirect({ name: 'sw', query });
  }
}
